'use strict';

import axios from 'axios';
import './style.css';
import CryptoJS from 'crypto-js';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export class ReviewsElements {
    constructor() {
        this.clicked = false;
        this.rateStars = document.querySelectorAll('.custom-rate .rate .rate__star');
        this.replyLinks = document.querySelectorAll('.reviews-block__reply');
        this.closeForms = document.querySelectorAll('.forgot-password');
        this.addReviewsForm = document.getElementById('add-reviews_form');
        this.answerForms = document.querySelectorAll('form#add-reviews_answer_form');
        this.photoInput = document.getElementById('review-photo-input');
        this.photoPreview = document.getElementById('photo-preview');

        this.setupPhotoUpload();
        this.setupRateStars();
        this.setupReplyLinks();
        this.setupCloseForm();
        this.setupAddReviewsForm();
        this.setupAnswerForms();

        Fancybox.bind("[data-fancybox='reviews']", {
            mainClass: 'reviews-fancybox',
        });
    }

    setupPhotoUpload() {
        if (this.photoInput) {
            const addPhotoBtn = document.querySelector(".add-photo");

            if (addPhotoBtn) {
                addPhotoBtn.addEventListener("click", () => {
                    this.photoInput.click(); // Открываем выбор файла
                });
            }

            this.photoInput.addEventListener("change", (event) => {
                this.handlePhotoUpload(event);
            });
        }
    }

    handlePhotoUpload(event) {
        const file = event.target.files[0];

        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {  // 5MB limit
            alert("Файл слишком большой! Максимальный размер — 5MB.");
            this.photoInput.value = ""; // Сбросить выбранный файл
            return;
        }

        if (!file.type.startsWith("image/")) {
            alert("Выбранный файл не является изображением!");
            return;
        }

        console.log("Выбранный файл:", file);

        const reader = new FileReader();
        reader.onload = (e) => {
            let base64String = e.target.result;

            if (base64String.startsWith("data:,")) {
                base64String = `data:image/png;base64,${base64String.split(",")[1]}`;
            }

            console.log("Исправленный base64:", base64String);

            this.photoPreview.innerHTML = `<img src="${base64String}" alt="Preview" style="max-width: 100px; max-height: 100px;">`;
        };

        reader.onerror = (error) => {
            console.error("Ошибка чтения файла:", error);
        };

        reader.readAsDataURL(file);
    }

    setupRateStars() {
        if (this.rateStars.length > 0) {
            this.rateStars.forEach((star, index) => {
                star.addEventListener('click', (e) => {
                    this.handleRateStarClick(star, index);
                });
            });
        }
    }

    handleRateStarClick(star, index) {
        const parent = star.parentElement;
        parent.setAttribute('data-rate', index + 1);

        const formRateInput = document.querySelector('input[name="form_rate"]');
        if (formRateInput) {
            formRateInput.value = index + 1;
        }

        this.clicked = true;
    }

    setupReplyLinks() {
        if (this.replyLinks.length > 0) {
            this.replyLinks.forEach(replyLink => {
                replyLink.addEventListener('click', (e) => {
                    this.handleReplyLinkClick(replyLink);
                });
            });
        }
    }

    setupCloseForm() {
        if (this.closeForms.length > 0) {
            this.closeForms.forEach(closeForm => {
                closeForm.addEventListener('click', (e) => {
                    this.handleCloseLinkClick(closeForm);
                });
            });
        }
    }

    handleCloseLinkClick(closeForm) {
        // Находим родительский блок для текущей ссылки
        var parentReviewsBlock = closeForm.closest('.reviews-block__add-answer');

        if (parentReviewsBlock) {
            parentReviewsBlock.style.display = 'none';
        }
    }

    handleReplyLinkClick(replyLink) {
        const reviewsBottom = replyLink.closest('.reviews-block__bottom');
        const addAnswer = reviewsBottom.nextElementSibling;

        if (addAnswer && addAnswer.classList.contains('reviews-block__add-answer')) {
            addAnswer.style.display = addAnswer.style.display === 'none' ? 'block' : 'none';
        }
    }

    setupAddReviewsForm() {
        if (this.addReviewsForm) {
            this.addReviewsForm.addEventListener('submit', (evt) => {
                this.handleFormSubmit(evt);
            });
        }
    }

    setupAnswerForms() {
        if (this.answerForms.length > 0) {
            this.answerForms.forEach(form => {
                form.addEventListener('submit', (evt) => {
                    this.handleFormSubmit(evt);
                });
            });
        }
    }

    handleFormSubmit(evt) {
        evt.preventDefault();
        this.sendReviewsForm(evt);
        return false;
    }

    updateLocalStorage = (key, newValue) => {
        // Хешируем новое значение
        const hashedNewValue = CryptoJS.SHA256(newValue).toString();

        // Проверяем существующее значение в localStorage
        const existingHashedValue = localStorage.getItem(key);

        // Если значения отличаются, обновляем localStorage
        if (existingHashedValue !== hashedNewValue) {
            localStorage.setItem(key, hashedNewValue);
        }
    };

    sendReviewsForm(evt) {
        const form = evt.target;
        const dataPath = form.getAttribute('data-path');
        const formData = new FormData(form);
        const userEmail = form.elements['email'].value.trim();

        // Добавляем файл
        if (this.photoInput.files.length > 0) {
            formData.append('photo', this.photoInput.files[0]);
        }

        axios.post(`${dataPath}/save_review.php`, formData)
            .then(response => {
                // Обработка успешного ответа
                if (response.data === "captcha_error") {
                    const errorElement = form.querySelector('.captcha-error-form-send-text');
                    if (errorElement) {
                        errorElement.style.display = 'block';
                    }
                } else if (response.data === "success") {
                    form.style.display = 'none';
                    const successMessage = form.nextElementSibling;

                    if (successMessage && successMessage.classList.contains('success-form-send-text')) {
                        successMessage.style.display = 'block';
                    }

                    const errorElement = form.querySelector('.captcha-error-form-send-text');
                    if (errorElement) {
                        errorElement.style.display = 'none';
                    }

                    this.updateLocalStorage('user_data.sha256_email_address', userEmail);
                }
            })
            .catch(error => {
                // Обработка ошибок при отправке запроса
                console.error(error);
                document.getElementById('form_success_message').style.display = 'none';
            });
    }
}