'use strict'

import './CatalogRootSection.css'
import React, { useEffect, useRef, useState } from 'react'
import CatalogSubSections from '../catalogSubSections/CatalogSubSections'

function CatalogRootSection (props) {
	const [subSections, setSubSections] = useState([])
	const ref = useRef()

	/**
	 * Подготовка списка подкатегорий
	 */
	useEffect(() => {
		if (!props.sections.length) {
			console.log('Sections array is empty')
			setSubSections([])
		} else {
			console.log('Current section:', props.section)
			console.log('All sections:', props.sections)

			let sub_sections = []

			for (let sub_section of props.sections) {
				console.log('Checking section:', {
					sectionId: sub_section.itemId,
					parentId: sub_section.parentId,
					expectedParentId: props.section.itemId,
					match: Number(sub_section.parentId) === Number(props.section.itemId)
				})

				if (!sub_section.hasOwnProperty('parentId') || sub_section.parentId === null ||
					Number(sub_section.parentId) !== Number(props.section.itemId)) continue
				sub_sections.push(sub_section)
			}

			console.log('Filtered subsections:', sub_sections)
			setSubSections(sub_sections)
		}
	}, [props.sections])

	return (
		<div className={`side-menu-item ${props.active ? 'active' : ''}`} onMouseOver={() => {
			if (!props.active) props.onMouseOver(props.section.itemId)
		}}>
			<div className="side-menu-block">
				<div className="image-block">
					{
						props.section.hasOwnProperty('imageSrc') && props.section.imageSrc && props.section.imageSrc.length ?
							<img
								className={`category-image ${props.active ? 'catalog-menu-image-alternative' : 'catalog-menu-image'}`}
								src={props.section.imageSrc} loading={'eager'} alt={props.section.title} title={props.section.title}/> :
							<></>
					}
				</div>
				<a href={props.section.link} className="side-menu-title">{props.section.title}</a>
			</div>
			{
				props.active ?
					<div className="side-menu-content">
						<div className="sub-menu" ref={ref}>
							<CatalogSubSections sections={subSections} allSections={props.sections}
							                    rootRef={ref}
							                    popularBlockUsed={false}/>
						</div>
					</div> :
					<></>
			}
		</div>
	)
}

export default CatalogRootSection